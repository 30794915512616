/**
 * Session Helper for the application.
 */
import cookie from "react-cookies";
import { COOKIES } from "./Config";

/**
 * Sets the current session context for the application.
 * @param data
 */
export function setContext(data) {
  localStorage.setItem("token", data.token);
  localStorage.setItem("isLoggedIn", true);
  localStorage.setItem("company_id", data.company_id);
  localStorage.setItem("company_name", data.company_name);
  localStorage.setItem("user_first_name", data.user_first_name);
  localStorage.setItem("user_last_name", data.user_last_name);
  localStorage.setItem("user_id", data.user_id);
  localStorage.setItem("email", data.email);
  localStorage.setItem("account_class", data.account_class);
  localStorage.setItem("user_type", data.is_manager);
  localStorage.setItem("account_level_id", data.account_level_id);
}

/**
 * Removes the current session context of the application.
 * @param url
 */
export function removeContext() {
  localStorage.removeItem("token");
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("company_id");
  localStorage.removeItem("company_name");
  localStorage.removeItem("user_first_name");
  localStorage.removeItem("user_last_name");
  localStorage.removeItem("user_id");
  localStorage.removeItem("email");
  localStorage.removeItem("account_class");
  localStorage.removeItem("user_type");
  localStorage.removeItem("account_level_id");
  localStorage.setItem("lastPath", "");
}

export function getUserFirstName() {
  return localStorage.getItem("user_first_name") || "";
}

export function getUserLastName() {
  return localStorage.getItem("user_last_name") || "";
}

export function getCompanyName() {
  return localStorage.getItem("company_name") || "";
}

export function getCompanyId() {
  return localStorage.getItem("company_id") || "";
}

export function getUserId() {
  return localStorage.getItem("user_id") || "";
}

export function getEmail() {
  return localStorage.getItem("email") || "";
}

export function getToken() {
  return localStorage.getItem("token") || null;
}

export function getAccountClass() {
  return localStorage.getItem("account_class") || null;
}

export function getUserType() {
  return localStorage.getItem("user_type" || null);
}

export function isLoggedIn() {
  if (getToken() && localStorage.getItem("isLoggedIn")) {
    return true;
  }
  return false;
}

export function setLastPath(path) {
  localStorage.setItem("lastPath", path);
}

export function getLastPath() {
  return localStorage.getItem("lastPath");
}

export function setDetailsShown(isShown) {
  return localStorage.setItem("detailsShown", isShown ? "true" : "false");
}

export function setCookie(key, value, domain = "", path = "/") {
  cookie.save(key, value, { path: path, domain: `.${domain}` });
}

export function loadCookie(key) {
  const appDomain = process.env.REACT_APP_APP_DOMAIN;
  return cookie.load(key, { path: "/", domain: `.${appDomain}` });
}

export function removeCookie(key) {
  const appDomain = process.env.REACT_APP_APP_DOMAIN;
  cookie.remove(key, { path: "/", domain: `.${appDomain}` });
}

export function removeCookies() {
  const appDomain = process.env.REACT_APP_APP_DOMAIN;
  const options = { path: "/", domain: `.${appDomain}`, expires: new Date() };
  cookie.remove(COOKIES.AUTH_TOKEN, options);
  cookie.remove(COOKIES.COMPANY_ID, options);
  cookie.remove(COOKIES.USER_ID, options);
  cookie.remove(COOKIES.USER_NAME, options);
  cookie.remove(COOKIES.HIDE_DC_TOAST_KEY, options);
  cookie.remove(COOKIES.HIDE_CC_TOAST_KEY, options);
  cookie.remove(COOKIES.USER_TYPE, options);
}

export const showAuthHeader = {
  headers: { Authorization: loadCookie(COOKIES.AUTH_TOKEN) },
};
export const pulsem = loadCookie(COOKIES.IS_PULSEM) === "true" ? true : false;
export const showCompanyId = loadCookie(COOKIES.COMPANY_ID);
export const showIsActive = loadCookie(COOKIES.IS_ACTIVE);
export const showIsManager = loadCookie(COOKIES.IS_MANAGER);
export const showUserId = loadCookie(COOKIES.USER_ID);
export const ghostUser = loadCookie(COOKIES.GHOST_USER);
export const showUsername = loadCookie(COOKIES.USER_NAME);
export const userType = loadCookie(COOKIES.USER_TYPE);
export const acceptsTerms =
  loadCookie(COOKIES.ACCEPT_TERMS) === "true" ? true : false;

/**
 * Returns true or false based on company feature flag value.
 * @param {*} component -- Component name
 * @param {*} operation -- operation (write or read)
 */
export const getCompanyFeatureFlags = (
  component,
  operation,
  featureFlags,
) => {
  let featureFlag = false;
  if (featureFlags) {
    let companyFeatureFlag = featureFlags.find(
      (item) => item.component === component && item.operation === operation,
    );
    featureFlag = companyFeatureFlag ? companyFeatureFlag.enabled : false;
  }

  return featureFlag;
};