import React from "react";
import { useSelector } from "react-redux";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import AppointmentWidgetBody from "src/components/settings/widgets/appointment_widgets/AppointmentWidgetBody";
import { addToast } from "src/utils/ToastUtil"

const AppointmentWidget = ({ widgetSettings }) => {
  const featureFlags = useSelector((state) => state.featureFlags.flags);

  const handleToast = () => {
    addToast({
      message: "Widget copied to clipboard!",
      type: "success",
    });
  };
  
  // feature flag check
  if ((!featureFlags.appointmentWrite)) {
    return null;
  }

  return (
    <SettingsCard
      loading={false}
      settingsName="Appointments Button"
      settingsDescription="Book more appointments! Link to your Customer Lobby appointment request page right from your website."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
        >
          <g fill="#7e7e7e">
            <g>
              <circle
                cx="1.406"
                cy="1.406"
                r="1.406"
                transform="translate(25.734 13.359)"
              />
              <path d="M30.375 2.813h-1.828V1.406a1.406 1.406 0 0 0-2.812 0v1.407h-6.4V1.406a1.406 1.406 0 0 0-2.812 0v1.407H10.2V1.406a1.406 1.406 0 0 0-2.812 0v1.407H5.625A5.631 5.631 0 0 0 0 8.438v21.937A5.631 5.631 0 0 0 5.625 36h10.758a1.406 1.406 0 0 0 0-2.812H5.625a2.816 2.816 0 0 1-2.812-2.812V8.438a2.816 2.816 0 0 1 2.812-2.813h1.758v1.406a1.406 1.406 0 0 0 2.813 0V5.625h6.328v1.406a1.406 1.406 0 0 0 2.813 0V5.625h6.4v1.406a1.406 1.406 0 0 0 2.813 0V5.625h1.828a2.816 2.816 0 0 1 2.813 2.813v8.016a1.406 1.406 0 0 0 2.813 0V8.438a5.631 5.631 0 0 0-5.629-5.625z" />
              <path
                d="M278.508 270a8.508 8.508 0 1 0 8.508 8.508 8.517 8.517 0 0 0-8.508-8.508zm0 14.2a5.7 5.7 0 1 1 5.7-5.7 5.7 5.7 0 0 1-5.7 5.7z"
                transform="translate(-251.016 -251.016)"
              />
              <path
                d="M374.445 332.883h-.633v-1.477a1.406 1.406 0 0 0-2.813 0v2.883a1.406 1.406 0 0 0 1.406 1.406h2.039a1.406 1.406 0 0 0 0-2.812z"
                transform="translate(-344.914 -306.797)"
              />
              <circle
                cx="1.406"
                cy="1.406"
                r="1.406"
                transform="translate(19.617 13.359)"
              />
              <circle
                cx="1.406"
                cy="1.406"
                r="1.406"
                transform="translate(13.5 19.477)"
              />
              <circle
                cx="1.406"
                cy="1.406"
                r="1.406"
                transform="translate(7.383 13.359)"
              />
              <circle
                cx="1.406"
                cy="1.406"
                r="1.406"
                transform="translate(7.383 19.477)"
              />
              <circle
                cx="1.406"
                cy="1.406"
                r="1.406"
                transform="translate(7.383 25.594)"
              />
              <circle
                cx="1.406"
                cy="1.406"
                r="1.406"
                transform="translate(13.5 25.594)"
              />
              <circle
                cx="1.406"
                cy="1.406"
                r="1.406"
                transform="translate(13.5 13.359)"
              />
            </g>
          </g>
        </svg>
      }
      settingsForm={
        <AppointmentWidgetBody
          widgetSettings={widgetSettings}
          handleToast={handleToast}
        />
      }
    />
  );
};

export default AppointmentWidget;
