import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import TrackSidebarComponent from "../../sidebar/TrackSidebarComponent";
import { DATE_RANGE, FILTER_BY, PLATFORM, SORT_BY, VIEW_BY } from "../../Data";
import TrackReviewListComponent from "../ManageTab/review-list/TrackReviewListComponent";
import TrackReviewsOverviewComponent from "../ManageTab/reviews-overview/TrackReviewsOverviewComponent";
import { originalState } from "./state_helper";
import PotraitOrientation from "src/shared/no-data/PotraitOrientation";
import { POTRAIT_ORIENTATION } from "src/shared/Constants";
import { QUERY_REVIEW_FILTERS } from "src/graphql/reviews/results/queries";
import { showCompanyId } from "src/shared/SessionHelper";
import { CACHE_AND_NETWORK } from "src/shared/Constants";
import "../ManageTab/ManageTabComponent.scss";
import { useSelector } from "react-redux";

const TrackTabComponent = (props) => {
  // state
  const [reset, setReset] = React.useState();
  const featureFlags = useSelector((state) => state.featureFlags.flags);
  const [state, setState] = useState(originalState);
  const [dateRange, setDateRange] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [nullifySorting, setNullifySorting] = useState(true);
  const [updateComponent, setUpdateComponent] = useState(false);
  const [title, setTitle] = useState(document.title);
  const [dateOptions, setDateOptions] = useState([]);
  const [kioskHandwrittenSource, setKioskHandwrittenSource] = useState('Handwritten')
  const { loading: loadingDateOptions, data: dataDateOptions } = useQuery(
    QUERY_REVIEW_FILTERS,
    {
      fetchPolicty: CACHE_AND_NETWORK,
      variables: {
        companyId: showCompanyId,
      },
    },
  );

  const sidebarOptions = () => {
    return {
      viewBy: VIEW_BY,
      sortBy: SORT_BY,
      filterBy: FILTER_BY,
      platform: PLATFORM,
      dateRange: dataDateOptions ? dataDateOptions : DATE_RANGE,
    };
  };

  const getDefaultFilters = () => {
    return {
      viewBy: sidebarOptions().viewBy[0],
      sortBy: sidebarOptions().sortBy[0],
      filterBy: sidebarOptions().filterBy[0],
      platform: sidebarOptions().platform[0],
      dateRange: sidebarOptions().dateRange[0],
    };
  };
  let [filters, setFilter] = useState(getDefaultFilters());

  // effects
  useEffect(() => {
    if (dataDateOptions) {
      const newDateOptions = dataDateOptions.filters.dateRange;
      setDateOptions(newDateOptions);
      setDateRange(
        props.selectedDateFilter >= 0 &&
          props.selectedDateFilter < newDateOptions.length
          ? newDateOptions[props.selectedDateFilter]
          : newDateOptions[1],
      );
    }
  }, [dataDateOptions]);

  useEffect(() => {
    let source;
    switch (state.source) {
      case "Email":
        source = "Email";
        break;
      case "Reviewme":
        source = "Review Me";
        break;
      case "CustomerCalls":
        source = "Customer Calls";
        break;
      case "CustomInvites":
        source = "Custom Invites";
        break;
      case "Handwritten":
        source = "Handwritten/Kiosk";
        break;
      default:
        break;
    }
  }, [state.source]);

  // feature flag check
  if (!featureFlags.reviewRead) {
    return null;
  }

  // render
  return (
    <DeviceOrientation>
      <Orientation orientation="landscape" alwaysRender={true}>
        <div id="track-tab-component">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 col-sm-12 sidebar-view">
                <TrackSidebarComponent
                  totalCount={totalCount}
                  setTotalCount={setTotalCount}
                  state={state}
                  updateComponent={updateComponent}
                  setState={setState}
                  filter={filters}
                  filters={filters}
                  setFilters={setFilter}
                  dateOptions={dateOptions}
                  sidebarData={sidebarOptions()}
                  dateRange={dateRange}
                  setDate={setDateRange}
                  currentPage={state.currentPage}
                  setKioskHandwrittenSource={setKioskHandwrittenSource}
                  kioskHandwrittenSource={kioskHandwrittenSource}
                  reset={reset}
                />
              </div>
              <div className="col-md-9 col-sm-12 px-sm-4 pm-4 content-view">
                <TrackReviewsOverviewComponent
                  dateRange={dateRange}
                  setNullifySorting={setNullifySorting}
                  state={state}
                  setState={setState}
                  setKioskHandwrittenSource={setKioskHandwrittenSource}
                  kioskHandwrittenSource={kioskHandwrittenSource}
                  filters={filters.dateRange}
                />
                <TrackReviewListComponent
                  dateRange={dateRange}
                  updateComponent={updateComponent}
                  setUpdateComponent={setUpdateComponent}
                  setNullifySorting={setNullifySorting}
                  totalCount={totalCount}
                  setKioskHandwrittenSource={setKioskHandwrittenSource}
                  nullifySorting={nullifySorting}
                  state={state}
                  setState={setState}
                  filters={filters}
                  setFilters={setFilter}
                  setReset={setReset}
                />
              </div>
            </div>
          </div>
        </div>
      </Orientation>
      {/* Will stay in DOM, but is only visible in portrait */}
      <Orientation orientation="portrait">
        <PotraitOrientation
          heading={POTRAIT_ORIENTATION.HEADING}
          message={POTRAIT_ORIENTATION.MESSAGE}
        />
      </Orientation>
    </DeviceOrientation>
  );
};
export default TrackTabComponent;
