import React, { useState, useEffect } from "react";
import "./TrackReviewsOverview.scss";
import "./ReviewsOverviewComponent.scss";
import notepad from "src/styles/assets/notepad.png";
import speechBubble from "src/styles/assets/speech-bubble.png";
import customerService from "src/styles/assets/customer-service.png";
import feedback from "src/styles/assets/feedback.png";
import emailImg from "src/styles/assets/email.png";
import TrackCardOne from "./TrackCardOne";
import ModalPopup from "src/shared/modal/ModalPopup";
import PublishInstructionsModal from "./PublishInstructionsModal";
import { reviewsBySource } from "./query_actions";
import { useSelector } from "react-redux";

const TrackReviewsOverviewComponent = ({
  state,
  setNullifySorting,
  setState,
  dateRange = {},
  filters,
  kioskHandwrittenSource,
  state: { companyId, authHeader, sourceData },
}) => {
  // state
  const [sourceLoading, setSourceLoading] = useState(true);
  const featureFlags = useSelector((state) => state.featureFlags.flags);
  const [filter, updateFilter] = useState(filters);
  const [publishInstructionModal, setPublishInstructionModal] = useState(false);
  const [counts, setCounts] = useState({
    email: 0,
    reviewMe: 0,
    customerCalls: 0,
    customInvites: 0,
    handwritten: 0,
  });

  // methods
  const updatePublishInstructionModal = () => {
    setPublishInstructionModal(!publishInstructionModal);
  };

  const getSourceData = async () => {
    let data = await reviewsBySource({ companyId, authHeader, dateRange });
    if (data) {
      setCounts({ ...data.getReviewsBySource });
      setState({
        ...state,
        sourceData: data.getReviewsBySource,
        currentCountHelper: "total",
        currentPage: 0,
      });
      setSourceLoading(false);
    }
  };

  // effects
  useEffect(() => {
    getSourceData();
    updateFilter(filters);
  }, [dateRange, sourceLoading]);

  // checks
  if (!sourceData) return null;
  if (sourceLoading) return null;
  let { reviewme, email, customerCalls, customInvites, handwritten } = counts;

  // feature flag check
  if (!featureFlags.reviewRead) {
    return null;
  }

  // render
  return (
    <div className="row mt-3 track-reviews-overview" id="reviews-overview">
      <div className="col-12">
        {publishInstructionModal ? (
          <ModalPopup
            isModalOpen={publishInstructionModal}
            modalHeader="Guidelines for Publishing Reviews"
            modalBody={<PublishInstructionsModal />}
            onModalClose={updatePublishInstructionModal}
          />
        ) : null}
        <div className="row">
          <div className="col-12">
            <div className="reviews-heading mb-3">
              Reviews Invitations Overview
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 container">
            <TrackCardOne
              setNullifySorting={setNullifySorting}
              sourceEl="Email"
              state={state}
              setState={setState}
              label="Email"
              count={email}
              image={emailImg}
            />
            <TrackCardOne
              setNullifySorting={setNullifySorting}
              sourceEl="Reviewme"
              state={state}
              setState={setState}
              label="Review Me / Web Text"
              count={reviewme}
              image={feedback}
            />
            <TrackCardOne
              setNullifySorting={setNullifySorting}
              sourceEl="CustomerCalls"
              state={state}
              setState={setState}
              label="Customer Calls"
              count={customerCalls}
              image={customerService}
            />
            <TrackCardOne
              setNullifySorting={setNullifySorting}
              sourceEl="CustomInvites"
              state={state}
              setState={setState}
              label="Custom Invites"
              count={customInvites}
              image={speechBubble}
            />
            <TrackCardOne
              setNullifySorting={setNullifySorting}
              sourceEl={kioskHandwrittenSource}
              state={state}
              setState={setState}
              label="Handwritten / Kiosk"
              count={handwritten}
              image={notepad}
              opacity={0.5}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackReviewsOverviewComponent;
