import React from "react";
// todo: needs to be replicated in old members area
// keep this component class based
// use css instead of scss
import "./HelpAndGetStarted.css";
import rocketImage from "src/assets/images/getting_started/rocket.svg";
import addCompanyLogoChecked from "src/assets/images/getting_started/add_company_logo_checked.svg";
import addCompanyLogo from "src/assets/images/getting_started/add_company_logo.svg";
import calendarChecked from "src/assets/images/getting_started/calendar_checked.svg";
import feedback from "src/assets/images/getting_started/feedback.svg";
import calendar from "src/assets/images/getting_started/calendar.svg";
import feedbackChecked from "src/assets/images/getting_started/feedback_checked.svg";
import followUpEmail from "src/assets/images/getting_started/follow_up_email.svg";
import followUpEmailChecked from "src/assets/images/getting_started/follow_up_email_checked.svg";
import insights from "src/assets/images/getting_started/insights.svg";
import contact_us from "src/assets/images/getting_started/contact_us.svg";
import about_app from "src/assets/images/getting_started/about_customer_lobby.svg";
import addNewUser from "src/assets/images/getting_started/add_new_user.svg";
import addNewUserChecked from "src/assets/images/getting_started/add_new_user_checked.svg";
import rightArrow from "src/assets/images/getting_started/right-arrow.svg";
import { GET_STARTED_COMPANY_STATUS } from "../../graphql/service_fusion/queries";
import ApolloClient from "apollo-boost";
import {
  API_GRAPHQL_URL,
  COOKIES,
  MEMBERS_NEW_COMPANY_URL,
} from "../../shared/Config";
import { loadCookie } from "../../shared/SessionHelper";
import SettingsContext from "src/shared/context/SettingsContext";
import * as sessionHelper from "src/shared/SessionHelper";
import * as config from "src/shared/Config";

import {
  PROFILE_SETTINGS_ROUTES,
  COMPANY_SETTINGS_ROUTES,
} from "src/shared/Constants";

import { HEADER_MENU } from "../../shared/Constants";
import GettingStartedModal from "./GettingStartedModal";
import AboutInsightsModal from "./AboutInsightsModal";
import { connect } from "react-redux";

const ListItem = ({
  icon,
  label,
  description,
  action,
  secondaryActionLabel,
  secondaryAction,
}) => {
  // render
  return (
    <div className="list-item" onClick={action}>
      <img className="preview-img" src={icon} alt={label} />
      <div className="content">
        <div className="label">{label}</div>
        <div className="description">{description}</div>
        {secondaryActionLabel !== null ? (
          <div className="secondary-label" onClick={secondaryAction}>
            {secondaryActionLabel}
          </div>
        ) : null}
      </div>
    </div>
  );
};
class HelpAndGetStarted extends React.Component {
  constructor(props) {
    super(props);

    this.settingsContext = SettingsContext;

    this.secondaryActionStarted = false;
    const partnerUserId = sessionHelper.loadCookie(
      config.COOKIES.PARTNER_USER_ID,
    );

    this.client = new ApolloClient({
      uri: API_GRAPHQL_URL,
    });

    var tabs = [
      {
        id: 0,
        label: "Home",
        content: [
          {
            id: 10,
            label: "About Customer Lobby app",
            icon: about_app,
            description: "Explore key features and possibilities",
            secondaryActionLabel: null,
          },
          {
            id: 11,
            label: "Get Started",
            icon: rocketImage,
            description:
              "Set up your company profile to get reviews and see how to create/manage campaigns.",
            secondaryActionLabel: null,
          },
          {
            id: 12,
            label: "Insights",
            icon: insights,
            description:
              "See industry benchmarks and other key metrics around the heath of your business.",
            secondaryActionLabel: "Learn more",
          },
          {
            id: 13,
            label: "Contact Us",
            icon: contact_us,
            description: "Need assistance? We’re here to help.",
            secondaryActionLabel: null,
          },
        ].map((rowData) => (
          <ListItem
            key={rowData.id}
            {...rowData}
            action={() => this.onRowClick(rowData.id)}
            secondaryAction={() => this.onSecondaryAction(rowData.id)}
          />
        )),
      },
      {
        id: 1,
        label: "Get Started",
        content: [
          {
            id: 20,
            label: "Set up Company Profile",
            icon: addCompanyLogo,
            description:
              "Add a company logo & website, used in email campaigns, your review page and email custom invites.",
            secondaryActionLabel: null,
          },
          {
            id: 21,
            label: "Add New Users",
            icon: addNewUser,
            description:
              "Add other team members to provide access to your Customer Lobby account.",
            secondaryActionLabel: null,
          },
          {
            id: 22,
            label: "Enable Follow-up emails",
            icon: followUpEmail,
            description:
              "Increase engagement of your postcard campaigns by sending an automated follow-up email.",
            secondaryActionLabel: null,
          },
          // {
          //   id: 23,
          //   label: "Communicatewith customers via text messaging",
          //   icon: textMessages,
          //   description:
          //     "Use auto responses for responding to after hours messages. Setup Quick Replies & Message Templates for easier conversations with customers.",
          //   secondaryActionLabel: null
          // },
          {
            id: 24,
            label: "Automate Review Invitations",
            icon: feedback,
            description:
              "Enable smart invites to automatically prompt your customers to leave reviews on Google or other sites of your choice.",
            secondaryActionLabel: null,
          },
          {
            id: 25,
            label: "Set up Appointments",
            icon: calendar,
            description:
              "Use your own, custom scheduling link on your Review page for people to book service with you.",
            secondaryActionLabel: null,
          },
        ].map((rowData) => (
          <ListItem
            key={rowData.id}
            {...rowData}
            action={() => this.onRowClick(rowData.id)}
            secondaryAction={() => this.onSecondaryAction(rowData.id)}
          />
        )),
      },
    ];

    this.state = {
      tabs: tabs,
      activeTab: 0,
      showGettingStartedModal: false,
      showInsightsModal: false,
      companyId: loadCookie(COOKIES.COMPANY_ID),
      token: loadCookie(COOKIES.AUTH_TOKEN),
      companyStatusData: null,
      showGetStarted: false,
    };

    this.populateData = this.populateData.bind(this);
    this.fetchCompanyStatus = this.fetchCompanyStatus.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    this.fetchCompanyStatus();
  }

  fetchCompanyStatus() {
    this.client
      .query({
        variables: { companyId: this.state.companyId },
        query: GET_STARTED_COMPANY_STATUS,
        context: { headers: { AUTHORIZATION: this.state.token } },
      })
      .then((response) => {
        if (response && response.data) {
          this.populateData(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => { });
  }

  populateData(data) {
    this.setCompanyStatusData(data);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeTab !== prevProps.activeTab) {
      this.setActiveTab(this.props.activeTab);
    }
    if (
      this.props.context.companyAppointmentEnabled !==
      prevProps.context.companyAppointmentEnabled
    ) {
      this.setEnabledIcon(
        "Appointment",
        this.props.context.companyAppointmentEnabled,
      );
    }
    if (
      this.props.context.followUpEnabled !== prevProps.context.followUpEnabled
    ) {
      this.setEnabledIcon("FollowUp", this.props.context.followUpEnabled);
    }
    if (
      this.props.context.smartInviteEnabled !==
      prevProps.context.smartInviteEnabled
    ) {
      this.setEnabledIcon("SmartInvite", this.props.context.smartInviteEnabled);
    }
    if (
      this.props.context.logoEnabled !== prevProps.context.logoEnabled ||
      this.props.context.websiteEnabled !== prevProps.context.websiteEnabled
    ) {
      this.setEnabledIcon(
        "CompanyLogo",
        this.props.context.logoEnabled && this.props.context.websiteEnabled,
      );
    }
    if (
      this.props.context.textAutoResponseEnabled !==
      prevProps.context.textAutoResponseEnabled
    ) {
      this.checkIfTextEnabled();
    }
    if (
      this.props.context.textQuickReplyEnabled !==
      prevProps.context.textQuickReplyEnabled
    ) {
      this.checkIfTextEnabled();
    }
    if (
      this.props.context.textMessageTemplateEnabled !==
      prevProps.context.textMessageTemplateEnabled
    ) {
      this.checkIfTextEnabled();
    }
  }

  checkIfTextEnabled() {
    const { featureFlags } = this.props
    if (
      this.props.context.textAutoResponseEnabled &&
      this.props.context.textMessageTemplateEnabled &&
      this.props.context.textQuickReplyEnabled &&
      featureFlags.textingWrite
    ) {
      this.setEnabledIcon("TextMessaging", true);
    } else {
      this.setEnabledIcon("TextMessaging", false);
    }
  }

  setEnabledIcon(type, settingEnabled) {
    const { featureFlags } = this.props
    var companyStatusData = this.state.companyStatusData;

    var tabs = [
      {
        id: 0,
        label: "Home",
        content: [
          {
            id: 10,
            label: "About Customer Lobby app",
            icon: about_app,
            description: "Explore key features and possibilities",
            secondaryActionLabel: null,
          },
          {
            id: 11,
            label: "Get Started",
            icon: rocketImage,
            description:
              "Set up your company profile to get reviews and see how to create/manage campaigns.",
            secondaryActionLabel: null,
          },
          {
            id: 12,
            label: "Insights",
            icon: insights,
            description:
              "See industry benchmarks and other key metrics around the heath of your business.",
            secondaryActionLabel: "Learn more",
          },
          {
            id: 13,
            label: "Contact Us",
            icon: contact_us,
            description: "Need assistance? We’re here to help.",
            secondaryActionLabel: null,
          },
        ].map((rowData) => (
          <ListItem
            key={rowData.id}
            {...rowData}
            action={() => this.onRowClick(rowData.id)}
            secondaryAction={() => this.onSecondaryAction(rowData.id)}
          />
        )),
      },
      {
        id: 1,
        label: "Get Started",
        content: [
          {
            id: 20,
            label: "Set up Company Profile",
            icon:
              type === "CompanyLogo"
                ? settingEnabled
                  ? addCompanyLogoChecked
                  : addCompanyLogo
                : companyStatusData != null &&
                  companyStatusData.company.logoUrl != null &&
                  companyStatusData.company.website != null &&
                  companyStatusData.company.website !== ""
                  ? addCompanyLogoChecked
                  : addCompanyLogo,
            description:
              "Add a company logo & website, used in email campaigns, your review page and email custom invites.",
            secondaryActionLabel: null,
          },
          {
            id: 21,
            label: "Add New Users",
            icon: addNewUserChecked,
            description:
              "Add other team members to provide access to your Customer Lobby account.",
            secondaryActionLabel: null,
          },
          {
            id: 22,
            label: "Enable Follow-up emails",
            icon:
              type === "FollowUp"
                ? settingEnabled
                  ? followUpEmailChecked
                  : followUpEmail
                : companyStatusData != null &&
                  companyStatusData.globalCampaignSetting.email != null
                  ? followUpEmailChecked
                  : followUpEmail,
            description:
              "Increase engagement of your postcard campaigns by sending an automated follow-up email.",
            secondaryActionLabel: null,
          },
          // {
          //   id: 23,
          //   label: "Communicate with customers via text messaging",
          //   icon: featureFlags.autoresponseWrite
          //   && featureFlags.quickReplyWrite
          //   && featureFlags.messageTemplatesWrite ? textMessagesChecked : textMessages,
          //   description:
          //     "Use auto responses for responding to after hours messages. Setup Quick Replies & Message Templates for easier conversations with customers.",
          //   secondaryActionLabel: null
          // },
          {
            id: 24,
            label: "Automate Review Invitations",
            icon:
              type === "SmartInvite"
                ? settingEnabled
                  ? feedbackChecked
                  : feedback
                : featureFlags.smartInviteWrite
                  ? feedbackChecked
                  : feedback,
            description:
              "Enable smart invites to automatically prompt your customers to leave reviews on Google or other sites of your choice.",
            secondaryActionLabel: null,
          },
          {
            id: 25,
            label: "Set up Appointments",
            icon:
              type === "Appointment"
                ? settingEnabled
                  ? calendarChecked
                  : calendar
                : featureFlags.appointmentWrite
                  ? calendarChecked
                  : calendar,
            description:
              "Use your own, custom scheduling link on your Review page for people to book service with you.",
            secondaryActionLabel: null,
          },
        ].map((rowData) => (
          <ListItem
            key={rowData.id}
            {...rowData}
            action={() => this.onRowClick(rowData.id)}
            secondaryAction={() => this.onSecondaryAction(rowData.id)}
          />
        )),
      },
    ];

    this.setState({ tabs });
  }

  setCompanyStatusData(companyStatusData) {
    const { featureFlags } = this.props

    if (featureFlags.appointmentWrite) {
      this.setState({ appointmentsEnabled: true });
      this.props.context.setCompanyAppointmentEnabled(true);
    }

    if (featureFlags.smartInviteWrite) {
      this.props.context.setSmartInviteEnabled(true);
    }

    if (
      companyStatusData != null &&
      companyStatusData.company.website !== null &&
      companyStatusData.company.website !== ""
    ) {
      this.props.context.setWebsiteEnabled(true);
    }

    if (
      companyStatusData != null &&
      companyStatusData.company.logoUrl != null
    ) {
      this.props.context.setLogoEnabled(true);
    }

    if (featureFlags.autoresponseWrite) {
      this.props.context.setTextAutoResponseEnabled(true);
    }

    if (featureFlags.quickReplyWrite) {
      this.props.context.setTextQuickReplyEnabled(true);
    }

    if (featureFlags.messageTemplatesWrite) {
      this.props.context.setTextMessageTemplateEnabled(true);
    }

    var tabs = [
      {
        id: 0,
        label: "Home",
        content: [
          {
            id: 10,
            label: "About Customer Lobby app",
            icon: about_app,
            description: "Explore key features and possibilities",
            secondaryActionLabel: null,
          },
          {
            id: 11,
            label: "Get Started",
            icon: rocketImage,
            description:
              "Set up your company profile to get reviews and see how to create/manage campaigns.",
            secondaryActionLabel: null,
          },
          {
            id: 12,
            label: "Insights",
            icon: insights,
            description:
              "See industry benchmarks and other key metrics around the heath of your business.",
            secondaryActionLabel: "Learn more",
          },
          {
            id: 13,
            label: "Contact Us",
            icon: contact_us,
            description: "Need assistance? We’re here to help.",
            secondaryActionLabel: null,
          },
        ].map((rowData) => (
          <ListItem
            key={rowData.id}
            {...rowData}
            action={() => this.onRowClick(rowData.id)}
            secondaryAction={() => this.onSecondaryAction(rowData.id)}
          />
        )),
      },
      {
        id: 1,
        label: "Get Started",
        content: [
          {
            id: 20,
            label: "Set up Company Profile",
            icon:
              companyStatusData != null &&
                companyStatusData.company.logoUrl != null &&
                companyStatusData.company.website != null &&
                companyStatusData.company.website !== ""
                ? addCompanyLogoChecked
                : addCompanyLogo,
            description:
              "Add a company logo & website, used in email campaigns, your review page and email custom invites.",
            secondaryActionLabel: null,
          },
          {
            id: 21,
            label: "Add New Users",
            icon: addNewUserChecked,
            description:
              "Add other team members to provide access to your Customer Lobby account.",
            secondaryActionLabel: null,
          },
          {
            id: 22,
            label: "Enable Follow-up emails",
            icon:
              companyStatusData != null &&
                companyStatusData.globalCampaignSetting.email != null
                ? followUpEmailChecked
                : followUpEmail,
            description:
              "Increase engagement of your postcard campaigns by sending an automated follow-up email.",
            secondaryActionLabel: null,
          },
          // {
          //   id: 23,
          //   label: "Communicate with customers via text messaging",
          //   icon: featureFlags.autoresponseWrite
          //   && featureFlags.quickReplyWrite
          //   && featureFlags.messageTemplatesWrite ? textMessagesChecked : textMessages,
          //   description:
          //     "Use auto responses for responding to after hours messages. Setup Quick Replies & Message Templates for easier conversations with customers.",
          //   secondaryActionLabel: null
          // },
          {
            id: 24,
            label: "Automate Review Invitations",
            icon: featureFlags.smartInviteWrite ? feedbackChecked : feedback,
            description:
              "Enable smart invites to automatically prompt your customers to leave reviews on Google or other sites of your choice.",
            secondaryActionLabel: null,
          },
          {
            id: 25,
            label: "Set up appointments",
            icon: featureFlags.appointmentWrite ? calendarChecked : calendar,
            description:
              "Use your own, custom scheduling link on your Review page for people to book service with you.",
            secondaryActionLabel: null,
          },
        ].map((rowData) => (
          <ListItem
            key={rowData.id}
            {...rowData}
            action={() => this.onRowClick(rowData.id)}
            secondaryAction={() => this.onSecondaryAction(rowData.id)}
          />
        )),
      },
    ];

    this.setState({ companyStatusData: companyStatusData, tabs: tabs });
  }

  setActiveTab(tab) {
    this.setState({
      showGetStarted: tab === 1 ? true : false,
      activeTab: tab,
    });
  }

  onSecondaryAction(rowId) {
    switch (rowId) {
      case 12:
        this.secondaryActionStarted = true;
        this.setState({ showInsightsModal: true });
        break;
      default:
        return null;
    }
  }

  onRowClick = async (rowId) => {
    // Remove items per feature flags
    const { featureFlags } = this.props
    const newState = { ...this.state };
    // alternatively can use id == 1 if title changes
    const getStartedObject = newState.tabs.find(
      (tab) => tab.label === "Get Started",
    );

    if (rowId === 11 && !featureFlags.campaignWrite) {
      const enableFollowUpEmailObj = getStartedObject.content.filter(
        (item) => item.key === 22,
      )[0];
      const index = getStartedObject.content.indexOf(enableFollowUpEmailObj);

      if (index >= 0) {
        getStartedObject.content.splice(index, 1);
      }
    }

    if (
      rowId == 11 &&
      (!featureFlags.reviewWrite || !featureFlags.smartInviteWrite)
    ) {
      const automateReviewsObj = getStartedObject.content.filter(
        (item) => item.key === 24,
      )[0];
      const index = getStartedObject.content.indexOf(automateReviewsObj);

      if (index >= 0) {
        getStartedObject.content.splice(index, 1);
      }
    }

    if (rowId === 11 && !featureFlags.appointmentWrite) {
      const appointmentSetupObj = getStartedObject.content.filter(
        (item) => item.key === 25,
      )[0];
      const index = getStartedObject.content.indexOf(appointmentSetupObj);

      if (index >= 0) {
        getStartedObject.content.splice(index, 1);
      }
    }

    this.setState(newState);

    switch (rowId) {
      case 10:
        this.setState({ showGettingStartedModal: true });
        break;
      case 11:
        this.setActiveTab(1);
        break;
      case 12:
        if (this.secondaryActionStarted === false) {
          if (
            this.state.companyStatusData != null &&
            this.state.companyStatusData.companyDataProcessingStatus != null &&
            this.state.companyStatusData.companyDataProcessingStatus
              .companyInsightsStatus !== "not_calculated"
          ) {
            window.location = `/companies/${sessionHelper.showCompanyId}/dashboard`;
          }
        }
        this.secondaryActionStarted = false;
        break;
      case 13:
        window.location = MEMBERS_NEW_COMPANY_URL + HEADER_MENU.help_center;
        break;
      case 20:
        window.location =
          MEMBERS_NEW_COMPANY_URL +
          PROFILE_SETTINGS_ROUTES.companyInformation;
        break;
      case 21:
        window.location = COMPANY_SETTINGS_ROUTES.users;
        break;
      case 22:
        window.location =
          MEMBERS_NEW_COMPANY_URL + COMPANY_SETTINGS_ROUTES.campaign;
        break;
      case 23:
        window.location =
          MEMBERS_NEW_COMPANY_URL +
          `${COMPANY_SETTINGS_ROUTES.texting}/message-responses`;
        break;
      case 24:
        window.location =
          MEMBERS_NEW_COMPANY_URL + COMPANY_SETTINGS_ROUTES.campaign;
        break;
      case 25:
        window.location =
          MEMBERS_NEW_COMPANY_URL + COMPANY_SETTINGS_ROUTES.campaign;
        break;
      default:
        return null;
    }
  };

  render() {
    // checks
    if (!this.props.show) {
      return null;
    }

    // render
    return (
      <div className="help-and-get-started">
        {/* close button */}

        <span className="close-button" onClick={this.props.onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 28.001 28"
          >
            <g>
              <path
                fill="#313541aa"
                d="M16.477 14L27.488 2.99A1.751 1.751 0 1 0 25.012.514L14 11.525 2.989.514A1.751 1.751 0 0 0 .513 2.99L11.525 14 .513 25.012a1.751 1.751 0 1 0 2.476 2.476L14 16.477l11.012 11.011a1.751 1.751 0 0 0 2.476-2.476z"
                transform="translate(0 -.001)"
              />
            </g>
          </svg>
        </span>

        {/* header */}
        <div className="header">Learning Center</div>

        <div> </div>

        {/* tabs */}
        <div className="tabs">
          {this.state.tabs.map((tab, index) => (
            <React.Fragment key={index}>
              <div
                onClick={() => this.setActiveTab(tab.id)}
                className="tab"
                key={tab}
              >
                {index === 0 || (index === 1 && this.state.showGetStarted) ? (
                  <div
                    className={
                      this.state.activeTab === tab.id ? "tablabelactive" : null
                    }
                  >
                    {tab.label}
                  </div>
                ) : null}
              </div>
              {this.state.tabs.length > 1 &&
                index < this.state.tabs.length - 1 &&
                this.state.showGetStarted ? (
                <div className="tab">
                  <img src={rightArrow} alt="Arrow" />
                </div>
              ) : null}
            </React.Fragment>
          ))}
        </div>
        <div className="content">
          {this.state.tabs.find((tab) => tab.id === this.state.activeTab)
            ? this.state.tabs.find((tab) => tab.id === this.state.activeTab)
              .content
            : null}
        </div>

        {/* getting started modal */}
        <GettingStartedModal
          show={this.state.showGettingStartedModal}
          onClose={() => this.setState({ showGettingStartedModal: false })}
        />
        <AboutInsightsModal
          show={this.state.showInsightsModal}
          onClose={() => this.setState({ showInsightsModal: false })}
        />
      </div>
    );
  }
}

// Map state and dispatch to props
const mapStateToProps = state => ({
  featureFlags: state.featureFlags.flags
});

// Connect the component to Redux
export default connect(mapStateToProps)(HelpAndGetStarted);

