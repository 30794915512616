import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import SidebarComponent from "../../sidebar/ReviewsSidebarComponent";
import {
  DATE_RANGE,
  FILTER_BY,
  PLATFORM,
  SORT_BY,
  VIEW_BY,
  VIEW_BY_TEMPLATE,
} from "../../Data";
import ReviewListComponent from "./review-list/ReviewListComponent";
import ReviewsOverviewComponent from "./reviews-overview/ReviewsOverviewComponent";
import { query } from "src/shared/GraphQLHandler";
import { REVIEWS_COUNTS, REVIEWS_META } from "./graphql-queries/queries";
import { loadCookie } from "src/shared/SessionHelper";
import { COOKIES } from "src/shared/Config";
import { QUERY_REVIEW_FILTERS } from "src/graphql/reviews/results/queries";
import { Spinner } from "react-bootstrap";
import { CACHE_AND_NETWORK } from "src/shared/Constants";
import "./ManageTabComponent.scss";
import { useSelector } from "react-redux";

const ManageTabComponent = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const filterParam = urlParams.get("filter");
  // state
  const [reset, setReset] = React.useState();
  const featureFlags = useSelector((state) => state.featureFlags.flags);
  let [viewBy, setViewBy] = useState(VIEW_BY);
  let [source, setSource] = useState(FILTER_BY);
  let [platform, setPlatform] = useState(PLATFORM);
  let [companyId] = useState(loadCookie(COOKIES.COMPANY_ID));
  const { loading: loadingDateOptions, data: dataDateOptions } = useQuery(
    QUERY_REVIEW_FILTERS,
    {
      fetchPolicy: CACHE_AND_NETWORK,
      variables: {
        companyId,
      },
    },
  );

  const [title, setTitle] = useState(document.title);
  useEffect(() => {
    reviewsMeta();
  }, []);

  /**
   * method invoked to get sidbar options
   */
  const sidebarOptions = () => {
    return {
      sortBy: SORT_BY,
      dateRange: dataDateOptions
        ? dataDateOptions.filters.dateRange
        : DATE_RANGE,
      viewBy: viewBy,
      filterBy: source,
      platform: platform,
    };
  };

  const getDefaultFilters = () => {
    return {
      viewBy: sidebarOptions().viewBy[2],
      sortBy: sidebarOptions().sortBy[0],
      filterBy: sidebarOptions().filterBy[0],
      platform: sidebarOptions().platform[0],
      dateRange: sidebarOptions().dateRange[1],
    };
  };

  let [filters, setFilter] = useState(getDefaultFilters());

  // effects
  useEffect(() => {
    setFilter({
      ...getDefaultFilters(),
      dateRange:
        props.selectedDateFilter >= 0 &&
          props.selectedDateFilter < sidebarOptions().dateRange.length
          ? sidebarOptions().dateRange[props.selectedDateFilter]
          : sidebarOptions().dateRange[1],
    });
  }, [loadingDateOptions]);

  useEffect(() => {
    if (filters && filters.dateRange && filters.dateRange.bucket) {
      reviewsCounts(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (filterParam === "all") {
      let filter = null;
      if (dataDateOptions) {
        filter = dataDateOptions.filters.dateRange.find((date) =>
          date.label.includes("All"),
        );
        setFilter({
          ...getDefaultFilters(),
          dateRange: filter,
        });
      }
    }
  }, [dataDateOptions]);

  useEffect(() => {
    const newFilters = { ...filters };
    if (
      props.selectedDateFilter >= 0 &&
      props.selectedDateFilter < sidebarOptions().dateRange.length
    ) {
      newFilters.dateRange =
        sidebarOptions().dateRange[props.selectedDateFilter];
    }
    if (
      props.selectedViewBy >= 0 &&
      props.selectedViewBy < sidebarOptions().viewBy.length
    ) {
      newFilters.viewBy = sidebarOptions().viewBy[props.selectedViewBy];
      reviewsCounts(newFilters);
    }
    setFilter(newFilters);
  }, [props.selectedDateFilter, props.selectedViewBy]);

  /**
   * method invoked to get default filter value
   */
  const reviewsCounts = (newFilters) => {
    let variables = {
      companyId: parseInt(companyId),
      dateFrom: newFilters.dateRange.value,
      dateBucket: newFilters.dateRange.bucket,
      platform: newFilters.platform.value,
      source: newFilters.filterBy.value,
      searchTerm: newFilters.searchTerm || "",
    };
    query(
      props.client,
      REVIEWS_COUNTS,
      function (error, response) {
        if (response && response.data && response.data.reviewCounts) {
          return setViewBy(getViewByMenu(response.data.reviewCounts));
        } else {
          console.log("Error in getting filters list: ", error);
        }
      },
      variables,
    );
  };

  const reviewsMeta = () => {
    let variables = {
      companyId: parseInt(companyId),
    };
    query(
      props.client,
      REVIEWS_META,
      function (error, response) {
        if (response && response.data && response.data.reviewsMeta) {
          setSource(response.data.reviewsMeta.source);
          setPlatform(response.data.reviewsMeta.platform);
        } else {
          console.log("Error in getting filters list: ", error);
        }
      },
      variables,
    );
  };

  const getViewByMenu = (counts) => {
    let viewByoptions = [];
    for (var key in counts) {
      // Do the loop and create a structure and pass the icon.
      var row = VIEW_BY_TEMPLATE[key];
      if (typeof row !== "undefined") {
        row.count = counts[key];
        viewByoptions.push(row);
      }
    }
    viewByoptions = viewByoptions.map((opt) => ({
      ...opt,
      count_formatted: opt.count,
    }));
    return viewByoptions;
  };

  // checks
  if (loadingDateOptions) {
    return (
      <div className="mt-4 text-center d-flex align-items-center justify-content-center">
        <Spinner size="lg" animation="grow" className="mr-2" />
        Populating data...
      </div>
    );
  }

  // feature flag check
  if (!featureFlags.reviewRead) {
    return null;
  }

  // render
  return (
    <div id="manage-tab-component">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-sm-12 sidebar-view">
            <SidebarComponent
              props={props}
              setFilters={setFilter}
              filters={filters}
              sidebarData={sidebarOptions()}
              viewByUpdate={reviewsCounts}
              defaultFilters={getDefaultFilters()}
              showReviewStatus={true}
              reset={reset}
            />
          </div>
          <div className="col-md-9 col-sm-12 px-sm-4 pm-4 content-view">
            <ReviewsOverviewComponent props={props} filters={filters} />
            <ReviewListComponent
              props={props}
              filters={filters}
              defaultFilters={getDefaultFilters()}
              setFilters={setFilter}
              refreshPage={reviewsCounts}
              setReset={setReset}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManageTabComponent;
