import { ffConstants } from "src/shared/Constants";

const sortFeatureFlags = (featureFlags) => {

  const getCompanyFeatureFlags = (
    component,
    operation,
  ) => {
    let featureFlag = false;
    if (featureFlags) {
      let companyFeatureFlag = featureFlags.find(
        (item) => item.component === component && item.operation === operation,
      );
      featureFlag = companyFeatureFlag ? companyFeatureFlag.enabled : false;
    }

    return featureFlag;
  };

  // Set preliminary objects to be used in setting feature flags
  const textingWrite = getCompanyFeatureFlags(
    ffConstants.texting,
    ffConstants.write,
  );
  // Appointment Flags
  const appointmentRead = getCompanyFeatureFlags(
    ffConstants.appointment,
    ffConstants.read,
  );
  const appointmentWrite = getCompanyFeatureFlags(
    ffConstants.appointment,
    ffConstants.write,
  );
  const customAppointmentWrite = getCompanyFeatureFlags(
    ffConstants.customAppointment,
    ffConstants.write,
  );
  const customerlobbyAppointmentWrite = getCompanyFeatureFlags(
    ffConstants.customerlobbyAppointment,
    ffConstants.write,
  );

  // Campaign Flags
  const campaignRead = getCompanyFeatureFlags(
    ffConstants.campaign,
    ffConstants.read,
  );
  const campaignWrite = getCompanyFeatureFlags(
    ffConstants.campaign,
    ffConstants.write,
  );
  const emailCampaignRead = getCompanyFeatureFlags(
    ffConstants.emailCampaign,
    ffConstants.read,
  );
  const emailCampaignWrite = getCompanyFeatureFlags(
    ffConstants.emailCampaign,
    ffConstants.write,
  );
  const postcardCampaignRead = getCompanyFeatureFlags(
    ffConstants.postcardCampaign,
    ffConstants.read,
  );
  const postcardCampaignWrite = getCompanyFeatureFlags(
    ffConstants.postcardCampaign,
    ffConstants.write,
  );
  const textCampaignRead = getCompanyFeatureFlags(
    ffConstants.textCampaign,
    ffConstants.read,
  );

  const textCampaignWrite =
    textingWrite &&
    getCompanyFeatureFlags(ffConstants.textCampaign, ffConstants.write);

  // Message Flags
  const messageRead = getCompanyFeatureFlags(
    ffConstants.message,
    ffConstants.read,
  );
  const messageWrite =
    textingWrite &&
    getCompanyFeatureFlags(ffConstants.message, ffConstants.write);
  const autoResponseWrite = getCompanyFeatureFlags(
    ffConstants.autoResponse,
    ffConstants.write,
  );
  const autoResponseAllWrite = getCompanyFeatureFlags(
    ffConstants.autoResponseAll,
    ffConstants.write,
  );
  const autoResponseAfterHoursWrite = getCompanyFeatureFlags(
    ffConstants.autoResponseAfterHours,
    ffConstants.write,
  );
  const messageTemplatesWrite = getCompanyFeatureFlags(
    ffConstants.messageTemplates,
    ffConstants.write,
  );
  const quickReplyWrite = getCompanyFeatureFlags(
    ffConstants.quickReply,
    ffConstants.write,
  );

  const mobileAppWrite = getCompanyFeatureFlags(
    ffConstants.mobileApp,
    ffConstants.write,
  );

  // Review Flags
  const reviewRead = getCompanyFeatureFlags(
    ffConstants.review,
    ffConstants.read,
  );
  const reviewWrite = getCompanyFeatureFlags(
    ffConstants.review,
    ffConstants.write,
  );
  const autoEmailInvitationWrite = getCompanyFeatureFlags(
    ffConstants.autoEmailInvitation,
    ffConstants.write,
  );
  const autoQueueCustomerCallsWrite = getCompanyFeatureFlags(
    ffConstants.autoQueueCustomerCalls,
    ffConstants.write,
  );
  const ignoreAutoInvitesForCommercialWrite = getCompanyFeatureFlags(
    ffConstants.ignoreAutoInvitesForCommercial,
    ffConstants.write,
  );
  const invitationDelayWrite = getCompanyFeatureFlags(
    ffConstants.invitationDelay,
    ffConstants.write,
  );
  const smartCallQueuinglWrite = getCompanyFeatureFlags(
    ffConstants.smartCallQueuing,
    ffConstants.write,
  );
  const smartInviteWrite = getCompanyFeatureFlags(
    ffConstants.smartInvite,
    ffConstants.write,
  );
  const prospectMarketingWrite = getCompanyFeatureFlags(
    ffConstants.prospect,
    ffConstants.write,
  );

  const magnetMailerWrite = getCompanyFeatureFlags(
    ffConstants.magnetMailer,
    ffConstants.write,
  );

  // The data is flat for convenience, but there is a hierarchy noted in comments
  return featureFlags = {
    // Appointment
    appointmentRead: appointmentRead || appointmentWrite,
    appointmentWrite: appointmentWrite,
    // Appointment > Custom Appointment
    customAppointmentWrite: appointmentWrite && customAppointmentWrite,
    // Appointment > Customer Lobby Appointment
    customerlobbyAppointmentWrite:
      appointmentWrite && customerlobbyAppointmentWrite,

    // Campaign
    campaignRead: campaignRead || campaignWrite,
    campaignWrite: campaignWrite,
    // Campaign > Email Campaign
    emailCampaignRead:
      (campaignRead || campaignWrite) &&
      (emailCampaignRead || emailCampaignWrite),
    emailCampaignWrite: campaignWrite && emailCampaignWrite,
    // Campaign > Postcard Campaign
    postcardCampaignRead:
      (campaignRead || campaignWrite) &&
      (postcardCampaignRead || postcardCampaignWrite),
    postcardCampaignWrite: campaignWrite && postcardCampaignWrite,
    // Campaign > Text Campaign
    textCampaignRead:
      (campaignRead || campaignWrite) && (textCampaignRead || textCampaignWrite),
    textCampaignWrite: campaignWrite && textCampaignWrite,

    // Message
    messageRead: messageRead || messageWrite,
    messageWrite: messageWrite,
    // Message > Message Templates
    messageTemplatesWrite: messageWrite && messageTemplatesWrite,
    // Message > Quick Reply
    quickReplyWrite: messageWrite && quickReplyWrite,
    // Message > Auto Response
    autoResponseWrite: messageWrite && autoResponseWrite,
    // Message > Auto Response > Auto Response After Hours
    autoResponseAfterHoursWrite:
      messageWrite && autoResponseWrite && autoResponseAfterHoursWrite,
    // Message > Auto Response > Auto Response All
    autoResponseAllWrite:
      messageWrite && autoResponseWrite && autoResponseAllWrite,

    // Mobile App
    mobileAppWrite: mobileAppWrite,

    // Review
    reviewRead: reviewRead || reviewWrite,
    reviewWrite: reviewWrite,
    // Review > Auto Email Invitation
    autoEmailInvitationWrite: reviewWrite && autoEmailInvitationWrite,
    // Review > Auto Queue Customer Calls
    autoQueueCustomerCallsWrite: reviewWrite && autoQueueCustomerCallsWrite,
    // Review > Ignore Auto Invites For Commercial
    ignoreAutoInvitesForCommercialWrite:
      reviewWrite && ignoreAutoInvitesForCommercialWrite,
    // Review > Invitation Delay
    invitationDelayWrite: reviewWrite && invitationDelayWrite,
    // Review > Smart Call Queuing
    smartCallQueuinglWrite: reviewWrite && smartCallQueuinglWrite,
    // Review > Smart Invite
    smartInviteWrite: reviewWrite && smartInviteWrite,

    // Texting
    textingWrite: textingWrite,

    // Prospect
    prospectMarketingWrite: prospectMarketingWrite,

    //magnetMailer
    magnetMailerWrite: magnetMailerWrite
  }
}

export { sortFeatureFlags }