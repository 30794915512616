import React, { Fragment, useEffect } from "react";
import Header from "src/components/layout/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { constants } from "src/shared/Constants";
import { toggleTheme } from "src/redux/themeSlice";
import { Container as RBContainer } from "react-bootstrap";
import ContainerRoutes from "src/routes/ContainerRoutes";
import { SettingsProvider } from "src/shared/context/SettingsContext";
import "./Container.scss";
import { useQuery } from "react-apollo";
import { showCompanyId } from "src/shared/SessionHelper";
import { QUERY_COMPANY_FEATURE_FLAGS } from "src/graphql/campaigns/postcard/queries";
import { setFeatureFlags } from "src/redux/featureFlagsSlice";
import { sortFeatureFlags } from "../../shared/FeatureFlagsHelper";

const Container = ({ client }) => {
  // variables
  const dispatch = useDispatch();
  const location = useLocation();
  const { darkMode } = useSelector((state) => state.theme);

  useEffect(() => {
    if (location?.pathname.includes("prospect") && !location?.pathname.includes("dashboard/prospectmarketing")) {
      dispatch(toggleTheme(true));
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.body.className = "dark-mode";
    }
  }, [darkMode]);

  useQuery(QUERY_COMPANY_FEATURE_FLAGS, {
    variables: {
      companyId: showCompanyId,
    },
    onCompleted: (data) => {
      if (data.companyFeatureFlags) {
        const result = sortFeatureFlags(data.companyFeatureFlags)
        dispatch(setFeatureFlags(result))
      }
    },
  });

  return (
    <RBContainer fluid className={`px-0 mx-0 main-container`}>
      {/* Setting context provider  */}
      <SettingsProvider client={client}>
        <Fragment>
          <Header client={client}></Header>
          <ToastContainer autoClose={constants.TOASTER_TIMER} limit={4} />
          <ContainerRoutes client={client} />
        </Fragment>
      </SettingsProvider>
    </RBContainer>
  );
};

export default Container;
