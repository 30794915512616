import React, { useState, useEffect } from "react";
import "src/components/company_users/AddOrEditCompanyUser.scss";
import { withRouter, Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import InformationSvg from "src/img/information.svg";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import {
  showCompanyId as companyId,
  showUserId,
  showIsManager as companyUserIsManager,
} from "src/shared/SessionHelper";
import { useMutation } from "@apollo/react-hooks";
import classnames from "classnames";
import {
  UPDATE_COMPANY_USER,
  CREATE_COMPANY_USER,
} from "src/graphql/users/mutations";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import {
  createCompanyUsersAction,
  updateCompanyUsersAction,
} from "src/graphql/users/actions";
import Form from "src/shared/styleguide/form/Form";
import {
  onDrop,
  openFinder,
  removeImg,
} from "src/utils/company_users/imageHelper";
import { maintainStateObj } from "src/utils/company_users/pageStateMaintainer";

const options = [
  { value: true, label: "Manager" },
  { value: false, label: "User" },
];

const AddOrEditCompanyUser = ({
  setDisplay,
  queriedUser,
  path,
  client,
  setSuccessMessage,
  openModal,
}) => {
  useDocumentTitle(
    path === "add" ? "Add User | Customer Lobby" : "Edit User | Customer Lobby",
  );
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordConfError, setShowPasswordConfError] = useState(false);
  const [showError, setShowError] = useState("");
  const [photoError, setPhotoError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [createCompanyUser] = useMutation(CREATE_COMPANY_USER);
  const [updateCompanyUser] = useMutation(UPDATE_COMPANY_USER);

  const validationsHelper = () => {
    return {
      password: Yup.string()
        .min(16, "Password must be atleast 16 characters")
        .required("Please enter a password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{16,})/,
          "Please enter a strong password",
        ),
      passwordConfirmation: Yup.string()
        .min(16, "Password must be atleast 16 characters")
        .oneOf([Yup.ref("password"), null], "Enter the same password as above"),
    };

    return null;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...queriedUser,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter a first name"),
      lastName: Yup.string().required("Please enter a last name"),
      email: Yup.string()
        .email()
        .required("Please enter a valid email address"),
      ...validationsHelper(),
    }),
    onSubmit: async (values) => {
      if (path === "add")
        return createCompanyUsersAction({
          setSuccessMessage,
          setDisplay,
          setSubmitting,
          setShowError,
          createCompanyUser,
          values,
          companyId,
        });

      if (values.password && values.password.length < 16) return null;
      if (values.password && !values.passwordConfirmation) return null;
      if (values.password && values.password !== values.passwordConfirmation)
        return null;

      if (!values.isActive && queriedUser.isActive)
        return openModal(
          () =>
            updateCompanyUsersAction({
              setSuccessMessage,
              setDisplay,
              setSubmitting,
              updateCompanyUser,
              values: formik.values,
              companyId,
            }),
          "Yes, Deactivate",
          "deactivate",
          `This will deactivate the user. A deactivated
      user cannot login to the application. Their profile
      and associated data will be retained until they are
      reactivated again.`,
        );

      if (values.isActive && !queriedUser.isActive)
        return openModal(
          () =>
            updateCompanyUsersAction({
              setSuccessMessage,
              setDisplay,
              setSubmitting,
              updateCompanyUser,
              setShowError,
              values,
              companyId,
            }),
          "Yes, Activate",
          "activate",
          "This will activate the user. An activated user can login to the application.",
        );

      updateCompanyUsersAction({
        setSuccessMessage,
        setDisplay,
        setSubmitting,
        updateCompanyUser,
        setShowError,
        values,
        companyId,
      });
    },
  });

  const handleImgSrc = () => {
    if (formik.values.avatarUrl) return formik.values.avatarUrl;

    if (formik.values.image)
      return `data:image/png;base64,${formik.values.image}`;

    return null;
  };

  const handlePasswordChange = (e, currFeild) => {
    formik.setFieldValue(currFeild, e.target.value, false);
  };

  useEffect(() => {
    formik.values.password && formik.values.password.length < 16
      ? setShowPasswordError(true)
      : setShowPasswordError(false);
    formik.values.passwordConfirmation &&
    formik.values.passwordConfirmation !== formik.values.password
      ? setShowPasswordConfError(true)
      : setShowPasswordConfError(false);
  }, [formik.values.passwordConfirmation, formik.values.password]);

  return (
    <div className="add-or-edit-user-container">
      <div className="image-upload-container">
        <Dropzone
          onDrop={(file) => onDrop({ file, formik, setPhotoError })}
          className="file-drop-zone"
          accept="image/jpeg, image/png"
          multiple={false}
          maxSize={4500000}
        >
          <div
            className={classnames(
              {
                "upload-image":
                  !formik.values.avatarUrl || !formik.values.image,
              },
              {
                "hide-upload-image":
                  formik.values.avatarUrl || formik.values.image,
              },
            )}
          >
            <div className="upload-text">Profile</div>
            <div className="upload-text">Photo</div>
          </div>
          <img
            className={classnames(
              {
                "show-user-profile-img":
                  formik.values.avatarUrl || formik.values.image,
              },
              {
                "user-profile-img":
                  !formik.values.avatarUrl || !formik.values.image,
              },
            )}
            src={handleImgSrc()}
            alt="profile-pic"
          />
        </Dropzone>
        <div className="upload-image-right-container">
          <div className="image-btns-container">
            <div onClick={openFinder} className="upload-image-btn">
              Upload Photo
            </div>
            <div
              onClick={() => removeImg({ formik })}
              className="remove-image-btn"
            >
              Remove
            </div>
          </div>
          <div className="image-validation-text">
            - Only JPEG and PNG image formats are accepted.
          </div>
          <div className="image-validation-text">
            - The image should be 400 x 200 pixels or larger.
          </div>
          <div className="image-validation-text">
            - The file size can not exceed 4MB.
          </div>
        </div>
      </div>
      <div
        style={{ opacity: photoError ? 1 : 0 }}
        className="users-add-edit-error-message"
      >
        {photoError}
      </div>

      <form
        className="users-add-edit-form-container"
        onSubmit={formik.handleSubmit}
      >
        <div style={{ marginTop: "26px" }} className="users-form-row">
          <div className="form-input-container">
            <div className="form-label-required">First Name</div>
            <input
              className="form-input"
              id="firstName"
              name="firstName"
              type="text"
              placeholder="Enter first name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            <div
              style={{
                opacity:
                  formik.errors.firstName && formik.touched.firstName ? 1 : 0,
              }}
              className="users-add-edit-error-message"
            >
              {formik.errors.firstName}
            </div>
          </div>
          <div className="form-input-container">
            <div className="form-label-required">Last Name</div>
            <input
              className="form-input"
              id="lastName"
              name="lastName"
              type="text"
              placeholder="Enter last name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            <div
              style={{
                opacity:
                  formik.errors.lastName && formik.touched.lastName ? 1 : 0,
              }}
              className="users-add-edit-error-message"
            >
              {formik.errors.lastName}
            </div>
          </div>
        </div>

        <div className="users-form-row">
          <div className="form-input-container">
            <div className="d-flex">
              <div
                style={{ marginRight: "6px" }}
                className="form-label-required"
              >
                Email Address
              </div>
              <PopupInfo message="All system emails and communications will be sent to this email address." />
            </div>
            <input
              className="form-input"
              id="email"
              name="email"
              type="email"
              placeholder="Enter email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {showError.includes("Email") ? (
              <div className="users-add-edit-error-message">{showError}</div>
            ) : null}
            <div
              style={{
                opacity: formik.errors.email && formik.touched.email ? 1 : 0,
              }}
              className="users-add-edit-error-message"
            >
              {formik.errors.email}
            </div>
          </div>
          <div className="form-input-container">
            <div className="form-label">Role</div>
            <Form.Select
              isSearchable={false}
              style={{
                height: "38px",
                borderRadius: "3px",
                border: "border: solid 1px #d6d3d3",
                width: "90%",
              }}
              isDisabled={true}
              disabled={
                Number(companyUserIsManager) === 0 ||
                (Number(companyUserIsManager) === 1 &&
                  showUserId === formik.values.company)
              }
              value={formik.values.isManager ? options[0] : options[1]}
              onChange={(e) => formik.setFieldValue("isManager", e.value)}
              options={options}
            />
          </div>
        </div>

        <div className="users-form-row">
          <div className="form-input-container">
            <div className="form-label">Status</div>
            <div className="d-flex">
              <div
                onClick={() => formik.setFieldValue("isActive", true)}
                style={{
                  borderTopLeftRadius: "4px",
                  borderBottomLeftRadius: "4px",
                }}
                className={
                  formik.values.isActive ? "active-input" : "inactive-input"
                }
              >
                Active
              </div>
              <div
                onClick={
                  formik.values.companyUserId === showUserId
                    ? null
                    : () => formik.setFieldValue("isActive", false)
                }
                style={{
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  ...(formik.values.companyUserId === showUserId
                    ? { opacity: 0.4 }
                    : null),
                }}
                className={classnames(
                  { "inactive-input": formik.values.isActive },
                  { "active-input": !formik.values.isActive },
                )}
              >
                Inactive
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "26px" }} className="users-form-row">
          <div className="form-input-container">
            <div
              className={classnames(
                { "form-label-required": path === "add" },
                { "form-label": path === "edit" },
              )}
            >
              Password
            </div>
            <input
              className="form-input"
              id="password"
              name="password"
              type="password"
              placeholder="Enter password"
              onChange={
                path === "add"
                  ? formik.handleChange
                  : (e) => handlePasswordChange(e, "password")
              }
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <div className="users-add-edit-error-message">
              {showError.includes("password") ? <div>{showError}</div> : null}
              <div className="mr-2">{formik.errors.password}</div>
            </div>
          </div>
          <div className="form-input-container">
            <div
              className={classnames(
                { "form-label-required": path === "add" },
                { "form-label": path === "edit" },
              )}
            >
              Confirm Password
            </div>
            <input
              className="form-input"
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              placeholder="Enter confirm password"
              onChange={
                path === "add"
                  ? formik.handleChange
                  : (e) => handlePasswordChange(e, "passwordConfirmation")
              }
              onBlur={formik.handleBlur}
              value={formik.values.passwordConfirmation}
            />
            <div
              style={{
                display:
                  (formik.errors.passwordConfirmation &&
                    formik.touched.passwordConfirmation &&
                    formik.values.passwordConfirmation !==
                      formik.values.password) ||
                  showPasswordConfError
                    ? "flex"
                    : "none",
              }}
              className="users-add-edit-error-message"
            >
              {"Enter the same password as above"}
            </div>
          </div>
        </div>
        <div className="password-info-container">
          <div className="password-info-txt">
            <img src={InformationSvg} alt="information-img" /> New password must
            contain:
            <ul>
              <li>At least 16 characters</li>
              <li>At least 1 lowercase letter</li>
              <li>At least 1 uppercase letter</li>
              <li>At least 1 number</li>
              <li>At least 1 special character</li>
            </ul>
          </div>
        </div>

        <div className="button-container">
          <button
            style={{
              opacity: submitting ? 0.4 : 1,
              width: path === "add" ? "100px" : "130px",
            }}
            disabled={submitting}
            className="add-user-btn"
            type="submit"
          >
            {path === "add" ? "Add User" : "Save Changes"}
          </button>
          <Link
            style={{ textDecoration: "none" }}
            to={`/companies/${companyId}/users`}
          >
            <div className="cancel-add-user-btn">Cancel</div>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default withRouter(AddOrEditCompanyUser);
