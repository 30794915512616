import { loadCookie } from "./SessionHelper";
/**
 * Constants Helper for the application.
 */

// For local nginx
// export const apiEndpoint = '/api/v1';
// export const apiEndpointV2 = '/api/v2';
export const apiEndpointUrl = "http://127.0.0.1:3000";
export const apiEndpoint = "http://127.0.0.1:3000/v1";
export const apiEndpointV2 = "http://127.0.0.1:3000/v2";
export const wwwEndPointUrl = "http://127.0.0.1:9393";

const appDomain = process.env.REACT_APP_APP_DOMAIN;

export const COMPANIES_TOKEN = `companies/${loadCookie("company_id")}`;
export const MEMBER_URL = process.env.REACT_APP_MEMBER_URL;
export const MEMBERS_NEW_COMPANY_URL =
  window.location.origin + `/companies/${loadCookie("company_id")}/`;
const WWW_URL = process.env.REACT_APP_WWW_URL;
const API_SECURE_URL = process.env.REACT_APP_API_SECURE_URL;
const USER_INFO_TOKEN = `/users/${loadCookie("user_id")}/edit`;

// For Build server
//  export const apiEndpoint = 'http://34.203.224.112/api/v1';
// export const apiEndpointV2 = 'http://34.203.224.112/api/v2';

export function awsSettings() {
  return {
    scotty_bucket: "customerlobby-scotty-development",
    communications_bucket: "customerlobby-communications-development",
    assembly_bucket: "customerlobby-assemblies-development",
    docs_bucket: "customerlobby-docs-development",
    upload_folder: "/Users/customerlobby/Projects/api/public/uploads",
    download_folder: "/Users/customerlobby/Projects/api/public/downloads",
    docs_folder: "/Users/dave/Projects/api/public/docs",
  };
}
/**
 * Constants Helper for the application.
 */
export const pusher = {
  DIRECT_CONNECT_SETTINGS: {
    CHANNEL: "websockets-company-",
    EVENT: "direct-connect-ui-update",
  },
  POSTCARD_FRONT_SETTINGS: {
    CHANNEL: "company-",
    EVENT: "custom-image-resize-complete",
    development: {
      app_id: 40189,
      key: "6e0926352979b122383e",
      secret: "74c5299f2db55342f9b0",
      cluster: "mt1",
    },
    test: {
      app_id: "40189",
      key: "0b79cb7bd63359741080",
      secret: "bf267924ad911d97030d",
    },
  },
};

export const dc_states = {
  collection: "collection",
  processing: "processing",
};

// TODO Add new email, text, postcard URLS
export const MEMBERS_URLS = {
  results: `${MEMBER_URL}${COMPANIES_TOKEN}/results`,
  text_messages: `${MEMBER_URL}${COMPANIES_TOKEN}/text-messages`,
  customers: `${MEMBER_URL}${COMPANIES_TOKEN}/customers`,
  reviewSettings: `${MEMBER_URL}${COMPANIES_TOKEN}/review_settings`,
  moreReviews: `${MEMBER_URL}${COMPANIES_TOKEN}/more_reviews`,
  manageReviews: `${MEMBER_URL}${COMPANIES_TOKEN}/manage_reviews`,
  trackReviews: `${MEMBER_URL}${COMPANIES_TOKEN}/track-reviews`,
  customerSuggestions: `${MEMBER_URL}${COMPANIES_TOKEN}/customer-suggestions`,
  campaignsResultsSummary: `${MEMBER_URL}${COMPANIES_TOKEN}/campaigns-results-summary`,
  manageCampaign: `${MEMBER_URL}${COMPANIES_TOKEN}/campaigns/manage`,
  appointments: `${MEMBER_URL}${COMPANIES_TOKEN}/appointments`,
  insights: `${MEMBER_URL}${COMPANIES_TOKEN}/insights`,
  settings: `${MEMBER_URL}${COMPANIES_TOKEN}/settings`,
  directConnectSettings: `/${COMPANIES_TOKEN}/direct-connect-settings`,
  users: `${MEMBER_URL}${COMPANIES_TOKEN}/users`,
  billingSettings: `${MEMBER_URL}${COMPANIES_TOKEN}/billing-settings`,
  syndicationSettings: `${MEMBER_URL}${COMPANIES_TOKEN}/syndication-settings`,
  widgets: `${MEMBER_URL}${COMPANIES_TOKEN}/widgets`,
  user_information: `${MEMBER_URL}${COMPANIES_TOKEN}${USER_INFO_TOKEN}`,
  login: `${MEMBER_URL}company/login`,
  requestSupport: `${MEMBER_URL}${COMPANIES_TOKEN}/request-support`,
  smsCampaignNew: `${MEMBER_URL}${COMPANIES_TOKEN}/sms_campaigns/new`,
  postcardCampaignNew: `${MEMBER_URL}${COMPANIES_TOKEN}/campaigns/new`,
};

export const WWW_URLS = {
  results: `${MEMBER_URL}${COMPANIES_TOKEN}/results`,
};
export const API_GRAPHQL_URL = `${API_SECURE_URL}/graphql`;

export const COOKIES = {
  AUTH_TOKEN: "auth_token",
  GHOST_USER: "ghost_user",
  COMPANY_ID: "company_id",
  USER_ID: "user_id",
  IS_ACTIVE: "is_active",
  IS_MANAGER: "is_manager",
  USER_NAME: "user_name",
  HIDE_DC_TOAST_KEY: "HIDE_DC_TOAST_KEY",
  HIDE_CC_TOAST_KEY: "HIDE_CC_TOAST_KEY",
  HIDE_IC_TOAST_KEY: "HIDE_IC_TOAST_KEY",
  ACCOUNT_CLASS: "account_class",
  PARTNER_COMPANY_ID: "partner_company_id",
  PARTNER_USER_ID: "partner_user_id",
  USER_TYPE: "user_type",
  ACCOUNT_LEVEL_ID: "account_level_id",
  IS_PULSEM: "is_pulsem",
  ACCEPT_TERMS: "accept_terms",
};

export const APPOLO_FETCH_POLICY = "no-cache";

export const DEFAULT_STAR_RATING_CONFIG = {
  dimension: "1rem",
  spacing: "0.05rem",
  emptyColor: "#999999",
  ratedColor: "#40a40b",
};

export const SWITCH_COLOR_CONFIG = {
  onColor: "#f47821",
  offColor: "#e2e2e2",
};

// Company account ids
export const COMPANY_ACCOUNT = {
  INSIGHT: "insights",
};
