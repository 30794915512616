import React, { useState, useEffect } from "react";
import AllReview from "src/img/star (1).svg";
import thumbsUp from "src/styles/assets/review-settings-icon/thumbs-up-hand-symbol.svg";
import thumbsDown from "src/styles/assets/review-settings-icon/thumbs-down-hand-symbol.svg";
import SidebarComponent from "../../sidebar/SidebarComponent";
import PrivateFeedbackListComponent from "./private-feedback-list/PrivateFeedbackListComponent";
import "./PrivateFeedbackTabComponent.scss";
import { query } from "src/shared/GraphQLHandler";
import {
  GET_PRIVATE_FEEDBACK_DISPOSITIONS,
  PRIVATE_FEEDBACK_DATE_RANGE,
  PRIVATE_FEEDBACK_SORT_BY,
} from "./graphql_queries/queries";
import { loadCookie } from "src/shared/SessionHelper";
import { COOKIES } from "src/shared/Config";
import { PRIVATE_FEEDBACK_DISPOSITIONS } from "src/shared/Constants";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_REVIEW_FILTERS } from "src/graphql/reviews/results/queries";
import { Spinner } from "react-bootstrap";
import { CACHE_AND_NETWORK } from "src/shared/Constants";
import SidebarMenuComponent from "src/shared/sidebar-menu/SidebarMenuComponent";
import SearchInput from "src/shared/styleguide/form/SearchInput";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";

/**
 * Component to render the private feedback tab component.
 * @param props - Props to be used from parent.
 * @returns {XML} -> Return the privateFeedbackTabComponent
 */
const PrivateFeedbackTabComponent = (props) => {
  // state
  const [reset, setReset] = React.useState();
  const featureFlags = useSelector((state) => state.featureFlags.flags);
  const [searchTerm, setSearchTerm] = React.useState("");
  let [dataLoading, setDataLoading] = useState(true);
  let [companyId] = useState(loadCookie(COOKIES.COMPANY_ID));
  let [dispositions, setDispositions] = useState([]);
  const [title, setTitle] = useState(document.title);
  let [filters, setFilters] = useState({
    viewBy: GET_PRIVATE_FEEDBACK_DISPOSITIONS,
    sortBy: PRIVATE_FEEDBACK_SORT_BY[0],
    dateRange: PRIVATE_FEEDBACK_DATE_RANGE[1],
  });
  let [isFirstLoad, setIsFirstTimeLoad] = useState(true);
  const { loading: loadingDateOptions, data: dataDateOptions } = useQuery(
    QUERY_REVIEW_FILTERS,
    {
      fetchPolicy: CACHE_AND_NETWORK,
      variables: {
        companyId,
      },
    },
  );

  /**
   * Provides list of dispositions for private feedback
   * @param dateRange - Selected date range
   */
  const getDispositions = (dateRange) => {
    let variables = {
      companyId: companyId,
      dateFrom: dateRange.value,
      dateBucket: dateRange.bucket,
      searchTerm: filters.searchTerm || "",
    };
    query(
      props.client,
      GET_PRIVATE_FEEDBACK_DISPOSITIONS,
      function(error, response) {
        if (
          response &&
          response.data &&
          response.data.privateFeedbackDispositions
        ) {
          var elements = [];
          response.data.privateFeedbackDispositions.map((element) => {
            elements.push({
              label: element.name,
              count: element.count,
              value: element.id,
              icon: iconType(element.id),
            });
          });
          setDispositions(elements);
          setDataLoading(false);
        } else {
          console.log("Error in getting dispositions list: ", error);
        }
      },
      variables,
    );
  };

  /**
   * Returns the icon for the disposition
   * @param disposition_id
   * @returns {*}
   */
  const iconType = (disposition_id) => {
    var icon;
    switch (disposition_id) {
      case PRIVATE_FEEDBACK_DISPOSITIONS.all_feedback:
        icon = AllReview;
        break;
      case PRIVATE_FEEDBACK_DISPOSITIONS.general_feedback:
        icon = thumbsUp;
        break;
      case PRIVATE_FEEDBACK_DISPOSITIONS.unhappy_feedback:
        icon = thumbsDown;
        break;
    }
    return icon;
  };

  /**
   * method invoked to get default filter value
   */
  const getDefaultFilters = () => {
    const dateRanges = dataDateOptions
      ? dataDateOptions.filters.dateRange
      : PRIVATE_FEEDBACK_DATE_RANGE;
    return {
      viewBy: dispositions[0],
      sortBy: PRIVATE_FEEDBACK_SORT_BY[0],
      dateRange:
        props.selectedDateFilter >= 0 &&
        props.selectedDateFilter < PRIVATE_FEEDBACK_DATE_RANGE.length
          ? dateRanges[props.selectedDateFilter]
          : filters.dateRange,
    };
  };

  /**
   * method invoked to get sidbar options
   */
  const sidebarOptions = () => {
    return {
      viewBy: dispositions,
      sortBy: PRIVATE_FEEDBACK_SORT_BY,
      dateRange: dataDateOptions
        ? dataDateOptions.filters.dateRange
        : PRIVATE_FEEDBACK_DATE_RANGE,
    };
  };

  const updateSearchTerm = debounce((term) => {
    setFilters({
      ...filters,
      searchTerm: term,
    });
  }, 300);

  /**
   * Dispositions change handler
   */
  /**
   * Date range change handler.
   */
  useEffect(() => {
    if (dataDateOptions && dataDateOptions.filters) {
      const dateRanges =
        dataDateOptions.filters.dateRange || sidebarOptions().dateRange;
      const newFilters = { ...filters };
      if (
        props.selectedDateFilter &&
        props.selectedDateFilter >= 0 &&
        dateRanges.length
      ) {
        newFilters.dateRange = dateRanges[props.selectedDateFilter];
      } else {
        newFilters.dateRange = dateRanges[1];
      }
      setFilters(newFilters);
    }
  }, [dataDateOptions, props.selectedDateFilter]);

  useEffect(() => {
    if (dispositions.length > 0 && isFirstLoad) {
      setFilters(getDefaultFilters());
      setIsFirstTimeLoad(false);
    } else if (dispositions.length > 0 && !isFirstLoad && filters.viewBy) {
      const selectedViewBy = dispositions.find(
        (disposition) => disposition.value === filters.viewBy.value,
      );
      if (selectedViewBy) {
        const filterObj = { ...filters, viewBy: selectedViewBy };
        setFilters(filterObj);
      }
    }
    getDispositions(filters.dateRange);
  }, [filters.dateRange, dataLoading, filters.searchTerm]);

  React.useEffect(() => {
    if (reset) {
      setSearchTerm("");
    }
  }, [reset]);

  if (!filters) return null;
  if (!filters.viewBy) return null;
  if (!filters.viewBy.count && filters.viewBy.count !== 0) return null;

  // checks
  if (loadingDateOptions || dataLoading) {
    return (
      <div className="mt-4 text-center d-flex align-items-center justify-content-center">
        <Spinner size="lg" animation="grow" className="mr-2" />
        Populating data...
      </div>
    );
  }

  // feature flag check
  if (!featureFlags.reviewRead) {
    return null;
  }
  
  // render
  return (
    <div id="private-feedback-tab-component" className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-sm-12 sidebar-view">
          {/* Sidebar Component */}

          {/* search */}
          <SidebarMenuComponent
            title="Search"
            menuItems={[]}
            menuItemsFilters={{}}
            clickHandler={() => {}}
            eventName="search"
          />

          <SearchInput
            value={searchTerm}
            maxLength={256}
            onKeyUp={(evt) => {
              if (evt.keyCode === 13) {
                updateSearchTerm(searchTerm);
              }
            }}
            onChange={(evt) => {
              const term = evt.target.value;
              setSearchTerm(term);
              if (term.length > 2 || term.length < searchTerm.length) {
                updateSearchTerm(term);
              }
            }}
            placeholder="Search by name, email or phone"
          />

          {filters && filters.viewBy ? (
            <SidebarComponent
              setFilters={setFilters}
              sidebarData={sidebarOptions()}
              filter={filters}
              reset={reset}
            />
          ) : null}
        </div>

        <div className="col-md-9 col-sm-12 px-sm-4 pm-4 content-view">
          {/* Private Feedback List Component*/}
          {filters && filters.viewBy ? (
            <PrivateFeedbackListComponent
              client={props.client}
              setFilters={setFilters}
              setReset={setReset}
              filters={{
                ...filters,
                viewBy: dispositions
                  ? dispositions.find(
                      (disposition) =>
                        disposition.value === filters.viewBy.value,
                    )
                  : filters.viewBy,
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default PrivateFeedbackTabComponent;
